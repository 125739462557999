var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.$style.table},[_c('div',{class:_vm.$style.th},_vm._l((_vm.columns),function(column,index){
var _obj;
return _c('div',{key:column + index,class:_vm.$style.td,style:({ flexGrow: _vm.columns[index].width }),on:{"click":function($event){return _vm.changeSort(column)}}},[_c('div',{class:[_vm.$style.tdInline, _vm.$style.colname]},[_vm._v(" "+_vm._s(column.name)+" ")]),_c('img',{class:[
            _vm.$style.tdInline,
            _vm.$style.arrow,
            ( _obj = {}, _obj[_vm.$style.asc] = column.sort == 2, _obj[_vm.$style.hidden] = column.sort == 0, _obj ) ],attrs:{"src":require("@/assets/images/icons/table/sort-arrow.svg")}})])}),0),_vm._l((_vm.tableData),function(user,index){
          var _obj;
return _c('div',{key:user + index},[_c('div',{class:[_vm.$style.tr, _vm.$style.user, _vm.$style.group],on:{"click":function($event){return _vm.toggle(user)}}},[_c('div',{class:[
            _vm.$style.expandButton,
            ( _obj = {}, _obj[_vm.$style.expanded] = user.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.td},[_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(user.userName)+" ")]),_c('div',{class:_vm.$style.text},[_vm._v(" - активное время ")]),_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(_vm.convertSecondsToDays(user.activeTime))+" ")])])]),_vm._l((user.history),function(group){
          var _obj;
return _c('div',{directives:[{name:"show",rawName:"v-show",value:(user.isExpanded),expression:"user.isExpanded"}],key:group.categoryName + index},[_c('div',{class:[_vm.$style.tr, _vm.$style.group, _vm.$style.indent],on:{"click":function($event){return _vm.toggle(group)}}},[_c('div',{class:[
              _vm.$style.expandButton,
              ( _obj = {}, _obj[_vm.$style.expanded] = group.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.td},[_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(group.categoryName)+" ")]),_c('div',{class:_vm.$style.text},[_vm._v(" - активное время ")]),_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(_vm.convertSecondsToDays(group.activeTime))+" ")]),_c('div',{class:_vm.$style.percent},[_vm._v(" "+_vm._s(group.percent)+"% ОА ")])])]),_vm._l((_vm.sortedLinks(group)),function(site,ind){
            var _obj, _obj$1, _obj$2;
return _c('div',{directives:[{name:"show",rawName:"v-show",value:(group.isExpanded),expression:"group.isExpanded"}],key:site.link + ind,class:[
            _vm.$style.tr,
            _vm.$style.indent,
            _vm.$style.colData,
            ( _obj = {}, _obj[_vm.$style.red] = site.productivity === 0, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.green] = site.productivity === 1, _obj$1 ),
            ( _obj$2 = {}, _obj$2[_vm.$style.yellow] = site.productivity === 2, _obj$2 ) ]},_vm._l((_vm.columns),function(column,num){return _c('div',{key:column.name + num,class:[_vm.$style.td, _vm.$style.data],style:({
              flexGrow: _vm.columns[num].width,
              marginLeft: _vm.getLeftMargin(column.field),
              paddingRight: _vm.getPadding(column.field),
            })},[_c('div',{style:({
                marginRight: _vm.getRightMargin(column.field),
                borderRight: '0px',
              })},[_vm._v(" "+_vm._s(_vm.getDataField(site[column.field], column.field))+" ")])])}),0)})],2)})],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }