<template>
  <div>
    <div :class="$style.table">
      <div :class="$style.th">
        <div
          v-for="(column, index) in columns"
          :key="column + index"
          :class="$style.td"
          :style="{ flexGrow: columns[index].width }"
          @click="changeSort(column)"
        >
          <div :class="[$style.tdInline, $style.colname]">
            {{ column.name }}
          </div>
          <img
            src="@/assets/images/icons/table/sort-arrow.svg"
            :class="[
              $style.tdInline,
              $style.arrow,
              {
                [$style.asc]: column.sort == 2,
                [$style.hidden]: column.sort == 0,
              },
            ]"
          >
        </div>
      </div>

      <div v-for="(user, index) in tableData" :key="user + index">
        <div
          :class="[$style.tr, $style.user, $style.group]"
          @click="toggle(user)"
        >
          <div
            :class="[
              $style.expandButton,
              { [$style.expanded]: user.isExpanded },
            ]"
          />
          <div :class="$style.td">
            <div :class="$style.bold">
              {{ user.userName }}
            </div>
            <div :class="$style.text">
              - активное время
            </div>
            <div :class="$style.bold">
              {{ convertSecondsToDays(user.activeTime) }}
            </div>
          </div>
        </div>

        <div
          v-for="group in user.history"
          v-show="user.isExpanded"
          :key="group.categoryName + index"
        >
          <div
            :class="[$style.tr, $style.group, $style.indent]"
            @click="toggle(group)"
          >
            <div
              :class="[
                $style.expandButton,
                { [$style.expanded]: group.isExpanded },
              ]"
            />
            <div :class="$style.td">
              <div :class="$style.bold">
                {{ group.categoryName }}
              </div>
              <div :class="$style.text">
                - активное время
              </div>
              <div :class="$style.bold">
                {{ convertSecondsToDays(group.activeTime) }}
              </div>
              <div :class="$style.percent">
                {{ group.percent }}% ОА
              </div>
            </div>
          </div>

          <div
            v-for="(site, ind) in sortedLinks(group)"
            v-show="group.isExpanded"
            :key="site.link + ind"
            :class="[
              $style.tr,
              $style.indent,
              $style.colData,
              {
                [$style.red]: site.productivity === 0,
              },
              {
                [$style.green]: site.productivity === 1,
              },
              {
                [$style.yellow]: site.productivity === 2,
              },
            ]"
          >
            <div
              v-for="(column, num) in columns"
              :key="column.name + num"
              :class="[$style.td, $style.data]"
              :style="{
                flexGrow: columns[num].width,
                marginLeft: getLeftMargin(column.field),
                paddingRight: getPadding(column.field),
              }"
            >
              <div
                :style="{
                  marginRight: getRightMargin(column.field),
                  borderRight: '0px',
                }"
              >
                {{ getDataField(site[column.field], column.field) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertSecondsToDays } from '@/helpers/format-date';
import { getDataField } from '@/views/reports/users/worktime/table-field';

export default {
  props: ['columns', 'tableData'],
  computed: {
    sortedColumn() {
      const index = this.columns.findIndex((x) => x.sort > 0);

      return {
        index,
        sort: this.columns[index].sort,
        field: this.columns[index].field,
      };
    },
  },
  methods: {
    getLeftMargin(field) {
      if (field === 'link' && this.columns.length === 4) return '-9px';
      return '0px';
    },
    getRightMargin(field) {
      if (field === 'name' && this.columns.length === 4) return '20px';
      return '0px';
    },
    getPadding(field) {
      if (field === 'link' && this.columns.length === 4) return '23px';
      return '';
    },
    getDataField(data, field) {
      return getDataField(data, field);
    },

    sortedLinks(group) {
      return group.events.slice(0).sort(this.compare);
    },

    compare(a, b) {
      const column = this.sortedColumn;
      const key = column.field;
      let a1 = a[key];
      let b1 = b[key];

      if (column.index === 0) {
        a1 = a1.toLowerCase();
        b1 = b1.toLowerCase();
      }

      if (column.index === 2) {
        if (a === 2) a = 0.5;
        if (b === 2) b = 0.5;
      }

      switch (column.sort) {
        case 1:
          if (a1 > b1) {
            return -1;
          }

          if (a1 < b1) {
            return 1;
          }
          return 0;
        case 2:
          if (a1 < b1) {
            return -1;
          }

          if (a1 > b1) {
            return 1;
          }

          return 0;
        default:
          return 0;
      }
    },
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    toggle(folder) {
      folder.isExpanded = !folder.isExpanded;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
  },
};
</script>

<style lang="scss" module>
.table {
  display: flex;
  flex-flow: column nowrap;
  font-weight: 400;
  line-height: 1.5;
  flex: 1 1 auto;
  word-break: break-word;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-collapse: collapse;
  width: 100%;
}
.th {
  font-weight: 700;
  background: #dbf1ff;
  word-break: break-word;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  font-weight: 400;
}
.th > .td {
  white-space: normal;
  word-break: break-word;
  border: 1px solid $borderColor;
  align-items: center;
  text-align: center;
  color: #232326;
  letter-spacing: -0.08px;
  font-size: 13px;
  padding: 4px 4px 4px 10px;
  cursor: pointer;
  justify-content: space-between;
}
.indent {
  margin-left: 20px;
}
.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
.percent {
  padding: 0px 3px;
  color: #2985bf;
  font-weight: 700;
  background: $white;
  border: 1px solid #cecece;
  border-radius: 10px;
  height: 23px;
  display: flex;
  align-items: center;
}
.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: auto;
  &.asc {
    transform: rotate(180deg);
  }
  &.hidden {
    visibility: hidden;
  }
}
.tdInline {
  display: inline-flex;
}
.colname {
  margin: auto;
}
.tr {
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  &:hover {
    background: $branchColor;
  }
}
.td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  word-break: break-word;
  overflow: inherit;
  text-overflow: ellipsis;
  min-width: 0px;
  white-space: inherit;
}
.data {
  border-left: 1px solid $borderColor;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding-left: 5px;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.24px;
  padding: 8px 4px 7px 20px;
  font-weight: 300;
}
.colData :nth-child(1) {
  margin-right: -10px;
}
.colData :nth-last-child(1) {
  border-right: 1px solid #e3e3e3;
}

.group {
  background: $light-gray;
  border: 1px solid #dfdfdf;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.24px;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 20px;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.user {
  margin-top: 7px;
}
.bold {
  font-weight: 500;
  padding: 5px 3px;
}
.text {
  padding: 5px 0;
}

.red {
  background-color: #ffb0b0;
  &:hover {
    background-color: $red;
  }
}

.green {
  background-color: #7cd890;
  &:hover {
    background-color: #1f8d37;
  }
}

.yellow {
  background-color: #ffecbc;
  &:hover {
    background-color: #d49a02;
  }
}

.blue {
  background-color: #94c2e0;
  &:hover {
    background-color: #1970a7;
  }
}
</style>
