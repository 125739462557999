import { getReportData } from '@/api/methods/reports/reports';

export function getWebsiteStat(array, dateFrom, dateTo) {
  const link = `/reports/statistics/websites?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}

export function getProgramStat(array, dateFrom, dateTo) {
  const link = `/reports/statistics/programs?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}
